

jQuery(document).ready(function ($) {

    //start header fixed
    $(window).scroll(function () {
        var sticky = $('.sticky'),
            scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass('fixed-theme');
        else sticky.removeClass('fixed-theme');
    });
    ////////////////////////+////////end header fixed
    $(window).ready(function () {
        var sticky = $('.sticky'),
            scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass('fixed-theme');
        else sticky.removeClass('fixed-theme');
    });


    //start custom_menu
    $("#mobnav").click(function () {
        $('body').addClass('noscroll');
        $('#cross').show();
        $('#mobnav').hide();
        $("header nav").animate({ 'left': '0' });
    });

    $("#cross").click(function () {
        $('body').removeClass('noscroll');
        $('#cross').hide();
        $('#mobnav').show();
        $("header nav").animate({ 'left': '-100%' });
    });
    $(".main_header  .mobile_menu nav .menu a").click(function () {
        // $('body').removeClass('noscroll');
        $('#cross').hide();
        $('#mobnav').show();
        $("header nav").animate({ 'left': '-100%' });
    });

    // submenu
    $(document).on('click', '#top li .down-icon', function () {
        $(this).prev().slideToggle();
    });

    // Remove svg from menu
    $('#top li.menu-item-has-children a svg').remove();
    $('#top li.menu-item-has-children').append('<span class="down-icon"></span>');
    ////////////////////////////////////end custom_menu
});

// responsive tab js 



$(".regular").slick({
    dots: true,
    infinite: true,
    lazyLoad: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },

    ],
});

$(".dieses").slick({
    dots: true,
    infinite: true,
    lazyLoad: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
});

$(".user").slick({
    dots: true,
    infinite: true,
    lazyLoad: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },

    ],
});

if ($(".dropdown").length) {
    $(document).on("click", ".dropdown-menu .dropdown-item", function (e) {
        e.preventDefault();
        if (!$(this).hasClass("active")) {
            var swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger me-3",
                },
                buttonsStyling: false,
            });
            swalWithBootstrapButtons
                .fire({
                    title: "Are you sure?",
                    text: "Do you really want to change your current language!",
                    icon: "warning",
                    confirmButtonText: "<i class='fas fa-check-circle me-1'></i> Yes, I am!",
                    cancelButtonText: "<i class='fas fa-times-circle me-1'></i> No, I'm Not",
                    showCancelButton: true,
                    reverseButtons: true,
                    focusConfirm: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (!$(this).hasClass("active")) {
                            $(".dropdown-menu .dropdown-item").removeClass("active");
                            $(this).addClass("active");
                            $(this)
                                .parents(".dropdown")
                                .find(".btn")
                                .html("<span class='flag-icon flag-icon-us me-1'></span>" + $(this).text());
                        }
                        Swal.fire({
                            icon: "success",
                            title: "Amazing!",
                            text: "Your current language has been changed successfully.",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                });
        }
    });
}


$(".vertical-center").slick({
    dots: false,
    infinite: true,
    vertical: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },]

});

$(document).ready(function () {
    $('.vertical-center').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $('.step_slider_right li').removeClass('active');
        $('.step_slider_right li:eq(' + currentSlide + ')').addClass('active');
    });
});




















